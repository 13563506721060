.publications-page__tab-content {
  padding-top: $layout-01;
  padding-bottom: $layout-01;
}

.publications-page__publications-list-header {
  color: $visited-link;
  font-weight: normal;
}

.bx--structured-list-row.no-border {
  border: none;
}

.bx--structured-list.low-margin {
  margin-bottom: 0;
}

.explore-tags {
  cursor: pointer;
}
