.publications-page__tab-content {
  padding-top: $layout-01;
  padding-bottom: $layout-01;
}

.publications-page__publications-list-header {
  color: $visited-link;
  font-weight: normal;
}

.bx--accordion__content {
  padding: 0;
}
